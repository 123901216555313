import '../style.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { GSLogo } from '../assets'

export function Footer(props) {
  return (
    <footer class="page-footer black darken-2 center-on-small-only pt-0 mt-0 pb-4">
      <div class="container">
        <div class="row">
          <div
            id="footer-logo"
            class="col-md-12 col-lg-2"
            style={{ marginTop: 0, marginLeft: -70 }}
          >
            <a href="https://www.greshamsmith.com" target="_blank">
              {/* <object
                width="212px"
                style={{ pointerEvents: "none" }}
                data="/gs_logo.svg"
              ></object> */}
              <GSLogo style={{ width: '212px' }} />
            </a>
          </div>

          <div
            id="footer-sub"
            class="col-md-12 col-lg-10 mt-4"
            style={{ paddingLeft: '85px' }}
          >
            <div style={{ marginTop: '30px' }}>
              <a
                class="footer-link"
                href={'https://www.greshamsmith.com'}
                target="_blank"
                style={{ whiteSpace: 'nowrap' }}
              >
                Gresham Smith
              </a>
              <a
                class="footer-link"
                href={'https://www.greshamsmith.com/privacy-policy/'}
                target="_blank"
                style={{ whiteSpace: 'nowrap' }}
              >
                Privacy Statement
              </a>
              <a
                class="footer-link"
                href={'mailto:mpath@greshamsmith.com'}
                target="_blank"
                style={{ whiteSpace: 'nowrap' }}
              >
                Contact
              </a>
            </div>
            <div
              id="footer-text"
              style={{
                fontSize: '10px',
                lineHeight: '20px',
                marginTop: '20px',
              }}
            >
              MPATH was developed as part of{' '}
              <a href="https://www.greshamsmith.com" target="_blank">
                Gresham Smith
              </a>
              's innovation incubator{' '}
              <a
                href="https://www.greshamsmith.com/innovation/"
                target="_blank"
              >
                Studio-X
              </a>
              .
              <br />
              Patent:{' '}
              <a
                href="https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11501501"
                target="_blank"
              >
                11,501,501
              </a>
            </div>
            <div
              id="footer-text"
              style={{
                fontSize: '10px',
                lineHeight: '20px',
                marginTop: '20px',
              }}
            >
              © 2023 Gresham Smith | Florida Cert. No. AAP000034 / CA3806 /
              IB26000797 / LC26000381
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
