// {
//   version: '1.0',
//   type: 'localization',
//   labels: ['label1', 'label2'],
//   _images: ['image1.jpg', 'image2.jpg', 'image3.jpg'],
//   _COS: 'actual cloud object storage instance',
//   annotations: {
//     'image1.jpg': [
//       {
//         label: 'label1',
//         x: 0,
//         y: 0,
//         x2: 0,
//         y2: 0
//       }
//     ]
//   }
// }
//
// | Ops                             | Effects                | Needs sync
// |---------------------------------|------------------------|------------------
// - setType(newType)                | type                   | yes
// - createLabel(newLabel)           | labels                 | yes
// - deleteLabel(label)              | labels, annotations    | yes
// - uploadImages([data])            | _images                | no
// - deleteImages([image])           | _images, annotations   | yes
// - createBox(image, newBox)        | annotations            | yes
// - deleteBox(image, box)           | annotations            | yes
//
// NOTE: deleteBox, if no more boxes we need to remove the annotation.
//
// - updateBox -> deleteBox + createBox
// - updateLabel -> createLabel + for each label (createBox + deleteBox) + deleteLabel
//
// | Getters
// |----------------------------------------------------------------------------
// - type
// - labels
// - annotations
// - images
// - getLabeledImages(true | false |  'label')
//     - getLabeledImages(true)  -> labeled
//     - getLabeledImages(false) -> unlabeled
//     - getLabeledImages('cat') -> 'cat'

import {
  setResources,
  setLoadingResources,
  invalidateResources,
} from './redux/resources'

import produce, { immerable } from 'immer'
import COS from './api/COSv2'
import { generateUUID } from 'Utils'
const fs = require('fs')

const listAllObjects = async (cos, params) => {
  const recursivelyQuery = async (continuationToken, list = []) => {
    const res = await cos.listObjectsV2({
      ...params,
      ContinuationToken: continuationToken,
    })
    const { NextContinuationToken, Contents = [] } = res.ListBucketResult
    const wrappedContents = Array.isArray(Contents) ? Contents : [Contents]
    const currentList = [...list, ...wrappedContents]
    if (NextContinuationToken) {
      return await recursivelyQuery(NextContinuationToken, currentList)
    }
    return currentList
  }
  return await recursivelyQuery()
}

export const IMAGE_REGEX = /\.(jpg|jpeg|png)$/i
const MODEL_REGEX = /\/model\.json$/i

const optional = (p, alt) => p.catch(() => alt)

const VERSION = '1.0'
export default class Collection {
  [immerable] = true
  type = undefined
  datasources = undefined
  cos = undefined
  bucket = undefined

  constructor(type, datasources) {
    this.type = type
    Object.defineProperty(this, 'datasources', {
      value: datasources,
      writable: true,
    })
  }

  static get EMPTY() {
    return new Collection(undefined, [])
  }

  static async load(endpoint, project, projectinfo) {
    //console.log('loading collection');

    const collectionJson = {
      version: '1.0',
      type: 'localization',
    }

    const collection = new Collection(collectionJson.type)
    collection.cos = project
    try {
      collection.user = window.user.email
    } catch (e) {
      window.location.reload()
    }
    collection.project = project
    console.log('collection', collection)

    return collection
  }

  getLabelMapCount(activeImage) {
    const labelCounts = {}
    this.labels.map((label) => {
      labelCounts[label] = 0
    })

    if (this.annotations != undefined) {
      try {
        for (var i = 0; i < this.annotations[activeImage].bboxes.length; i++) {
          const bb = this.annotations[activeImage].bboxes[i]
          if (this.labels.indexOf(bb['label']) > -1) {
            labelCounts[bb['label']] += 1
          }
        }
      } catch (e) {}
    }
    return labelCounts

    // return this.labels.reduce((acc, label) => {
    //   acc[label] = Object.keys(this.annotations).reduce((acc, image) => {
    //     acc += this.annotations[image].bboxes.reduce((acc, annotation) => {
    //       if (annotation.label === label) {
    //         acc++
    //       }
    //       return acc
    //     }, 0)
    //     return acc
    //   }, 0)
    //   return acc
    // }, {})
  }

  // TODO: Maybe memoize this function.
  getDataSources() {
    return this.datasources
  }

  getGroupedImages() {
    const all = this.images
    const labeled = this.getLabeledImages(true)
    const unlabeled = this.getLabeledImages(false)
    const otherLabels = this.labels.reduce((acc, label) => {
      acc[label] = this.getLabeledImages(label)
      return acc
    }, {})
    return { project_data: all, ...otherLabels }
  }

  setType(type, syncComplete) {
    const collection = produce(this, (draft) => {
      draft.type = type
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  updateLabels(newLabels, syncComplete) {
    const collection = produce(this, (draft) => {
      draft.labels = newLabels
    })

    // remove the label from the db
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        project: collection.cos,
        tags: newLabels.join(','),
        user: window.user.email,
      }),
    }

    fetch('/api/projects/update', requestOptions)
      .then((response) => response.json())
      .then((data) => {})

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  createLabel(newLabel, syncComplete) {
    const collection = produce(this, (draft) => {
      draft.labels.push(newLabel)
    })

    // remove the label from the db
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        project: collection.cos,
        tags: collection.labels.join(','),
        user: window.user.email,
      }),
    }

    fetch('/api/projects/update', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        /*
        fetch(`/api/projects?user=${window.user.email}`)
          .then(res => res.json())
          //.then(json => recursivelyFetchResources(json.next_url, json.resources))
          .then(allResources => {
            // if (!isCancelled) {
            // Alphabetize the list by name.
            allResources.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            console.log('projects', allResources);
            //window.projects = projects;
            setResources(allResources)
            
            // }
          })
          .catch(error => {
            console.error(error)
          })
        */
      })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  deleteLabel(label, syncComplete) {
    const collection = produce(this, (draft) => {
      draft.labels.splice(
        draft.labels.findIndex((l) => l === label),
        1
      )
      // TODO: We might have some interesting corner cases:
      // if someone deletes a label right as we label something with the label.
      Object.keys(draft.annotations).forEach((image) => {
        draft.annotations[image].bboxes = draft.annotations[
          image
        ].bboxes.filter((a) => a.label !== label)
        // Ensure images without annotations are removed.
        if (draft.annotations[image].bboxes.length === 0) {
          delete draft.annotations[image]
        }
      })
    })

    // remove the label from the db
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        project: collection.cos,
        tags: collection.labels.join(','),
        user: window.user.email,
      }),
    }

    fetch('/api/projects/update', requestOptions)
      .then((response) => response.json())
      .then((data) => {})

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  uploadImages(images, syncComplete) {
    // TODO: Do we need to wait until these requests finish?
    images.forEach((image) =>
      this.cos.putObject({
        Bucket: this.bucket,
        Key: image.name,
        Body: image.blob,
      })
    )

    const collection = produce(this, (draft) => {
      const imageNames = images.map((image) => image.name)
      draft.images = [...new Set([...imageNames, ...draft.images])]
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  deleteImages(images, syncComplete) {
    console.log(images)

    /*
    // TODO: Do we need to wait until this request finishes?
    const objects = images.map((image) => ({ Key: image }))
    this.cos.deleteObjects({
      Bucket: this.bucket,
      Delete: {
        Objects: objects,
      },
    })

    const collection = produce(this, (draft) => {
      images.forEach((image) => {
        draft.images.splice(
          draft.images.findIndex((i) => i === image),
          1
        )
        // TODO: This could possibly cause an undefined error if someone deletes
        // an image when someone else adds a box to the image. We should check
        // if the image exists in `createBox` and `deleteBox`
        delete draft.annotations[image]
      })
    })
    */

    const collection = produce(this, (draft) => {
      images.forEach((image) => {
        draft.images.splice(
          draft.images.findIndex((i) => i === image),
          1
        )
        // TODO: This could possibly cause an undefined error if someone deletes
        // an image when someone else adds a box to the image. We should check
        // if the image exists in `createBox` and `deleteBox`
        delete draft.annotations[image]
      })
    })

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        project: collection.cos,
        frames: images,
        user: collection.user,
      }),
    }

    fetch('/api/frames/delete', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('deleted', data)
      })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  labelImages(images, label, syncComplete) {
    return this.labelImagesV2(images, label, false, syncComplete)
  }

  labelImagesV2(images, label, onlyOne, syncComplete) {
    const collection = produce(this, (draft) => {
      images.forEach((image) => {
        if (onlyOne) {
          draft.annotations[image] = [] // only allow one label
        }

        if (!draft.annotations[image]) {
          draft.annotations[image] = []
        }
        // Only inset one.
        if (
          !draft.annotations[image].find(
            (box) =>
              box.label === label &&
              box.x === undefined &&
              box.y === undefined &&
              box.x2 === undefined &&
              box.y2 === undefined
          )
        ) {
          draft.annotations[image].unshift({ label: label })
        }
      })
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  clearLabels(images, syncComplete) {
    const collection = produce(this, (draft) => {
      images.forEach((image) => {
        delete draft.annotations[image]
      })
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  createBox(image, newBox, syncComplete) {
    const collection = produce(this, (draft) => {
      if (!draft.annotations[image]) {
        draft.annotations[image] = {}
        draft.annotations[image].bboxes = []
      }
      draft.annotations[image].bboxes.unshift(newBox)
    })
    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  deleteBox(image, box, syncComplete) {
    const collection = produce(this, (draft) => {
      draft.annotations[image].splice(
        draft.annotations[image].findIndex((oldBBox) => {
          if (!box.id) {
            return (
              oldBBox.label === box.label &&
              oldBBox.x === undefined &&
              oldBBox.y === undefined &&
              oldBBox.x2 === undefined &&
              oldBBox.y2 === undefined
            )
          }
          return oldBBox.id === box.id
        }),
        1
      )
      if (draft.annotations[image].length === 0) {
        // We don't need to emit a special event for deleting the entire
        // annotation. The annotation will get stripted eventually, because this
        // function will always be called by all clients before syncing.
        delete draft.annotations[image]
      }
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  bootstrap(images, annotations, syncComplete) {
    const _collection = this.uploadImages(images, false)
    const collection = produce(_collection, (draft) => {
      draft.labels = [...new Set([...draft.labels, ...annotations.labels])]
      draft.annotations = Object.assign(
        draft.annotations,
        annotations.annotations
      )
    })

    syncBucket(this.cos, this.bucket, collection, syncComplete)
    return collection
  }

  addModel(model) {
    const collection = produce(this, (draft) => {
      draft.models = [
        `/api/proxy/${draft.cos.endpoint}/${draft.bucket}/${model}`,
        ...draft.models,
      ]
    })
    return collection
  }

  noOP(...params) {
    const syncComplete = params[params.length - 1]
    syncBucket(this.cos, this.bucket, this, syncComplete)
    return this
  }

  toJSON() {
    return {
      version: VERSION,
      type: this.type,
      labels: this.labels,
      annotations: this.annotations,
    }
  }
}

// TODO: We can pass a promise chain here so we can wait for that to complete as
// well.
const syncBucket = async (cos, bucket, collection, syncComplete) => {
  if (syncComplete) {
    /*
    const string = JSON.stringify(collection.toJSON())
    const blob = new Blob([string], { type: 'application/json;charset=utf-8;' })
    await cos.putObject({
      Bucket: bucket,
      Key: '_annotations.json',
      Body: blob,
    })
    */
    syncComplete()
  }
}
