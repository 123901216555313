import COS from 'api/COSv2'
import { defaultEndpoint } from 'endpoints'

// Actions
const SET = 'cloud-annotations/devices/SET'

// Reducer
export default function reducer(devices = null, action = {}) {
  switch (action.type) {
    case SET:
      return action.devices
    default:
      return devices
  }
}

export const setDevices = b => ({ type: SET, devices: b })

// Side Effects
export const loadDevices = async instanceId => {
  var url = `/api/devices?key=YGxafc8RkiF6nCRBf7kogBabyJfX48mR2XsXRZ`;
  const res = await fetch(url);
  let devices = await res.json();
  
  // projects = projects.map(bucket => ({
  //   id: bucket.name,
  //   name: bucket.name,
  //   status: bucket.status ? bucket.status : 'pending',
  //   tags: bucket.tags ? bucket.tags.join('---') : '',
  //   updatedAt: new Date(bucket.updatedAt) //.toLocaleDateString()
  // }))
  //console.log(projects)
  return setDevices(devices)
}
