import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Header } from './common/Header'
import { Footer } from './common/Footer'
import { initLibs } from './common/libs'
import './style.css'
import { useGoogleAnalytics } from 'googleAnalyticsHook'

export function Studies(props) {
  useGoogleAnalytics('lander-studies')
  useEffect(() => {
    initLibs()
  }, [])

  const [errorMesssage, setErrorMessage] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = () => {
    setErrorMessage(null)
    setSubmitting(true)
    // get all the fields and validate

    const name = document.getElementById('name').value
    const email = document.getElementById('email').value
    const affiliation = document.getElementById('affiliation').value
    const studylocation_city = document.getElementById('studylocation-city')
      .value
    const studylocation_state = document.getElementById('studylocation-state')
      .value
    const studylocation_country = document.getElementById(
      'studylocation-country'
    ).value
    const studysetting = document.getElementById('studysetting').value
    const interest = document.getElementById('interest').value

    // validation
    const errors = []
    if (name == '') {
      errors.push('&nbsp;&nbsp;Please enter valid name.')
    }
    if (email == '' || email.indexOf('@') == -1) {
      errors.push('&nbsp;&nbsp;Please enter valid email.')
    }
    if (interest == '') {
      errors.push('&nbsp;&nbsp;Please enter valid interest.')
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join('<br/>'))
      setSubmitting(false)
    } else {
      const payload = {
        name,
        email,
        affiliation,
        city: studylocation_city,
        state: studylocation_state,
        country: studylocation_country,
        setting: studysetting,
        interest,
      }
      console.log(payload)

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }

      fetch('/api/inquiry', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            setSuccess(true)
            setSubmitting(false)
          }, 600)
        })
    }
  }

  return (
    <>
      <Header />
      <div id="content">
        <section class="view" id="intro">
          <div class="site-bg-img d-flex align-items-center">
            <div class="container">
              <div class="row no-gutters">
                <div class="col-md-12 col-lg-12 text-left margins">
                  <div class="header" style={{ marginTop: 24 }}>
                    Studies
                  </div>
                  <div class="subtext" style={{ lineHeight: '32px' }}>
                    <p>
                      Studies give you an opportunity to look at how stress and
                      comfort relate to a local area or project.
                    </p>
                  </div>
                </div>
                <div
                  class="col-md-12 col-lg-12 text-left margins"
                  style={{ marginTop: 0 }}
                >
                  <div class="subtext" style={{ lineHeight: '32px' }}>
                    <p>
                      You will soon have the ability to create your own study
                      directly on this page. If you are interested in gaining
                      early access to the studies feature, please use the
                      following form to engage our team.
                    </p>
                  </div>
                </div>
                <div
                  class="col-md-12 col-lg-12 text-left margins"
                  style={{ marginTop: 34, height: 'auto' }}
                >
                  {success == false ? (
                    <div class="rendered-form">
                      {errorMesssage ? (
                        <div class="error-form">
                          Error submitting form:
                          <br />
                          <span
                            dangerouslySetInnerHTML={{ __html: errorMesssage }}
                          ></span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div class="formbuilder-text form-group field-text">
                        <label for="name" class="formbuilder-text-label">
                          Your name *
                        </label>
                        <input
                          placeholder="First Last"
                          type="text"
                          class="form-control"
                          name="name"
                          access="false"
                          id="name"
                        />
                      </div>
                      <div class="formbuilder-text form-group field-text">
                        <label for="email" class="formbuilder-text-label">
                          Email *
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          access="false"
                          id="email"
                        />
                      </div>
                      <div class="formbuilder-text form-group field-text">
                        <label for="affiliation" class="formbuilder-text-label">
                          Affiliation
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="affiliation"
                          access="false"
                          id="affiliation"
                        />
                      </div>
                      <div class="formbuilder-text form-group field-text">
                        <label
                          for="studylocation"
                          class="formbuilder-text-label"
                        >
                          Study Location
                        </label>
                        <input
                          placeholder="City"
                          type="text"
                          class="form-control"
                          name="studylocation-city"
                          access="false"
                          id="studylocation-city"
                          style={{ width: '35%', marginRight: '2.5%' }}
                        />
                        <input
                          placeholder="State"
                          type="text"
                          class="form-control"
                          name="studylocation-state"
                          access="false"
                          id="studylocation-state"
                          style={{ width: '20%', marginRight: '2.5%' }}
                        />
                        <input
                          placeholder="Country"
                          type="text"
                          class="form-control"
                          name="studylocation-country"
                          access="false"
                          id="studylocation-country"
                          style={{ width: '20%' }}
                        />
                      </div>
                      <div class="formbuilder-text form-group field-text">
                        <label
                          for="studysetting"
                          class="formbuilder-text-label"
                        >
                          Study Setting
                        </label>
                        <select
                          class="form-control"
                          name="studysetting"
                          id="studysetting"
                        >
                          <option value="private" selected="true">
                            Private
                          </option>
                          <option value="public">Public</option>
                        </select>
                      </div>
                      <div class="formbuilder-text form-group field-text">
                        <label
                          for="interest"
                          class="formbuilder-text-label"
                          style={{ verticalAlign: 'top' }}
                        >
                          Interest *
                        </label>
                        <textarea
                          placeholder="Tell us why the empathic approach interests you..."
                          type="textarea"
                          class="form-control"
                          name="interest"
                          access="false"
                          id="interest"
                          rows={6}
                        />
                      </div>
                      <div class="text-right" style={{ marginTop: 10 }}>
                        {submitting == false ? (
                          <button
                            class="primary"
                            onClick={handleSubmit}
                            style={{ width: 140 }}
                          >
                            Submit
                          </button>
                        ) : (
                          <div>Submitting Form...</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div class="success-form">
                      Thanks! Form submitted successfully. We will reach out to
                      you soon.
                    </div>
                  )}
                </div>

                <div
                  class="col-md-12 col-lg-12"
                  style={{ marginTop: 74, marginBottom: 100 }}
                >
                  <div
                    style={{
                      fontFamily: 'AtlasGrotesk-Light',
                      fontStyle: 'italic',
                      fontSize: 17,
                      textAlign: 'center',
                    }}
                  >
                    There are several ongoing studies happening around the
                    globe. Check out some of the locations below!
                  </div>
                  <iframe
                    src="/view/337/mobile"
                    width={'100%'}
                    height={556}
                    style={{
                      border: 0,
                      backgroundColor: '#eee',
                      marginTop: 10,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
