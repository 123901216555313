import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Header } from './common/Header'
import { Footer } from './common/Footer'
import { initLibs } from './common/libs'
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import {
  MobilePhones,
  DontStress,
  AppLogo,
  Store,
  AndroidStore,
  IosStore,
  DontStressMobile,
  IosButton,
  AndroidButton,
} from './assets'
import { useGoogleAnalytics } from 'googleAnalyticsHook'

export function AppPage(props) {
  useGoogleAnalytics('lander-app')

  useEffect(() => {
    initLibs()
  }, [])

  return (
    <>
      <Header />
      <div id="content">
        <section class="view" id="intro">
          <div class="site-bg-img d-flex align-items-center">
            <div class="container">
              <div class="row no-gutters" style={{ marginTop: '-15px' }}>
                <div
                  class="col-md-12 col-lg-6 text-center margins mobilePhones"
                  style={{ marginTop: -30 }}
                >
                  <MobilePhones
                    id="mobile-phone-svg"
                    style={{ width: '660px' }}
                  />
                </div>
                <div
                  class="col-md-12 col-lg-6 text-center subtext mobilePhoneText"
                  style={{
                    marginTop: 120,
                    paddingLeft: 15,
                    display: '',
                  }}
                >
                  <AppLogo id="applogo" />
                  <div
                    class="col-md-12 col-lg-12 text-center subtext appIcon"
                    style={{
                      marginTop: 15,
                      fontWeight: 'bold',
                      fontFamily: 'AtlasGrotesk-Regular',
                      fontSize: 20,
                      marginBottom: 50,
                    }}
                  >
                    Download App:
                  </div>

                  <div
                    class="col-md-6 col-lg-6 text-center"
                    style={{ display: 'inline-block', width: '50%' }}
                  >
                    <a
                      href="https://apps.apple.com/us/app/mpath-empathic-insights/id6443997300"
                      target="_blank"
                    >
                      <span id="button-desktop">
                        <IosStore />
                      </span>
                      <span id="button-mobile">
                        <IosButton />
                      </span>
                    </a>
                    {/* <div
                      style={{
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginTop: 10,
                      }}
                    >
                      * Android App Coming Soon...
                    </div> */}
                  </div>
                  <div
                    class="col-md-6 col-lg-6 text-center"
                    style={{
                      display: 'inline-block',
                      width: '50%',
                      top: 5,
                      position: 'relative',
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.empathicinsights"
                      target="_blank"
                    >
                      <span id="button-desktop">
                        <AndroidStore />
                      </span>
                      <span id="button-mobile" class="android-button">
                        <AndroidButton />
                      </span>
                    </a>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12 text-left margins">
                  <div class="header" style={{ marginTop: 20 }}>
                    It's Easy, Don't Stress!
                  </div>
                  <p class="subtext" style={{ lineHeight: '32px' }}>
                    Once you have the app installed on your phone, it is easy to
                    start seeing your stress and comfort data! Just walk through
                    the guided opt-in process and then use your favorite
                    activity recording outlet to begin to sync your apps. MPATH
                    is compatible with most modern wearables and activity
                    platforms!
                  </p>
                  <div
                    style={{
                      marginTop: 40,
                      textAlign: 'center',
                      marginBottom: 80,
                    }}
                  >
                    <span id="customers-desktop">
                      <DontStress />
                    </span>
                    <span id="customers-mobile">
                      <DontStressMobile />
                    </span>
                    <div
                      style={{
                        marginTop: '25px',
                        fontSize: 17,
                        lineHeight: '30px',
                        marginLeft: '-18px',
                      }}
                    >
                      Having Trouble?
                      <br />
                      <Link to={'/faq'} style={{ textDecoration: 'underline' }}>
                        Get Help
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
