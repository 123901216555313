import COS from 'api/COSv2'
import { defaultEndpoint } from 'endpoints'

// Actions
const SET = 'cloud-annotations/datasets/SET'

// Reducer
export default function reducer(datasets = null, action = {}) {
  switch (action.type) {
    case SET:
      return action.datasets
    default:
      return datasets
  }
}

export const setDatasets = (b) => ({ type: SET, datasets: b })

// Side Effects
export const loadDatasets = async (instanceId) => {
  var url = `/api/datasources/shared?key=YGxafc8RkiF6nCRBf7kogBabyJfX48mR2XsXRZ&user=${window.user.email}`
  const res = await fetch(url)
  let datasets = await res.json()

  // projects = projects.map(bucket => ({
  //   id: bucket.name,
  //   name: bucket.name,
  //   status: bucket.status ? bucket.status : 'pending',
  //   tags: bucket.tags ? bucket.tags.join('---') : '',
  //   updatedAt: new Date(bucket.updatedAt) //.toLocaleDateString()
  // }))
  //console.log(projects)
  return setDatasets(datasets)
}
