import { combineReducers } from 'redux'
import accountsReducer from './accounts'
import resourcesReducer from './resources'
import wmlResourcesReducer from './wmlResources'
import profileReducer from './profile'
import projectsReducer from './projects'
import datasetsReducer from './datasets'
import devicesReducer from './devices'
import collectionReducer from './collection'
import editorReducer from './editor'
import autoLabelReducer from './autoLabel'

export default combineReducers({
  accounts: accountsReducer,
  resources: resourcesReducer,
  wmlResources: wmlResourcesReducer,
  profile: profileReducer,
  projects: projectsReducer,
  datasets: datasetsReducer,
  collection: collectionReducer,
  editor: editorReducer,
  autoLabel: autoLabelReducer,
  devices: devicesReducer,
})
