import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Header } from './common/Header'
import { Footer } from './common/Footer'
import { initLibs } from './common/libs'
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import { Customers, MobilePhones, CustomersMobile } from './assets'
import { useGoogleAnalytics } from 'googleAnalyticsHook'

export function Main(props) {
  useGoogleAnalytics('lander-main')

  useEffect(() => {
    initLibs()
  }, [])

  return (
    <>
      <Header />
      <div id="content">
        <section class="view" id="intro">
          <div class="site-bg-img align-items-center">
            <div class="container">
              <div class="row no-gutters" style={{ marginTop: '-15px' }}>
                <div
                  class="col-md-12 col-lg-6 text-center margins mobilePhones"
                  style={{ marginTop: -30 }}
                >
                  <MobilePhones
                    id="mobile-phone-svg"
                    style={{ width: '660px' }}
                  />
                </div>
                <div
                  class="col-md-12 col-lg-6 text-left margins subtext mobilePhoneText"
                  style={{
                    marginTop: 105,
                    paddingLeft: 15,
                  }}
                >
                  <p class="headerText">
                    We want to make the world a better place and you can help!
                  </p>
                  <p class="headerText">
                    MPATH uses your activity data to calculate where you are
                    feeling stress or comfort. The neat thing about stress and
                    comfort is that when a lot of people have similar
                    experiences, it starts to point to the place or environment
                    as the cause of your response.
                  </p>
                  <p class="headerText">
                    We believe that this novel approach to understanding how you
                    are experiencing the world around you allows us to create
                    better places and experiences over time.
                  </p>
                  <p class="headerText">
                    Imagine identifying where the most relaxing place in a city
                    is, routing home on the least stressful route, or
                    understanding how much downtime you need to be in an optimal
                    condition. All of this is now possible with MPATH!
                  </p>
                  <div
                    class="text-center downloadnow"
                    style={{ marginTop: 50 }}
                  >
                    <Link to="/app">
                      <button class="primary">Download App</button>
                    </Link>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12 text-left margins">
                  <div class="header" style={{ marginTop: 20 }}>
                    Partners
                  </div>
                  <div
                    class="subtext headerText"
                    style={{ lineHeight: '32px' }}
                  >
                    Our MPATH partners are currently blazing a trail of
                    innovation with studies and pilot projects across the globe.
                    Find out how you can be a part of this movement by creating
                    your own study!
                  </div>

                  <div class="text-center" style={{ marginTop: 32 }}>
                    <Link to="/studies">
                      <button class="primary">Create a Study</button>
                    </Link>
                  </div>
                  <div
                    style={{
                      marginTop: 50,
                      textAlign: 'center',
                      marginBottom: 100,
                    }}
                  >
                    <span id="customers-desktop">
                      <Customers />
                    </span>
                    <span id="customers-mobile" style={{ zoom: 1.2 }}>
                      <CustomersMobile />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
