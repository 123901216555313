import { useEffect } from 'react'
import GoogleAnalytics from 'react-ga4'

export const useGoogleAnalytics = (page) => {
  useEffect(() => {
    if (
      window.top.location.pathname.indexOf('/mobile') > -1 ||
      window.top.location.pathname.indexOf('/sharedata') > -1 ||
      window.top.location.pathname == '/' ||
      window.top.location.pathname == '/about' ||
      window.top.location.pathname == '/app' ||
      window.top.location.pathname == '/studies' ||
      window.top.location.pathname == '/faq'
    ) {
      document.body.className = 'light'
    } else {
      document.body.className = 'dark'
    }
    // console.log('CALLING GA', window.location.pathname)
    // GoogleAnalytics.pageview(page)
    // console.log('PAGE', page)
    // console.log(window.location.pathname)
    GoogleAnalytics.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: window.location.pathname,
    })
  }, [page])
}
