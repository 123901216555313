import COS from 'api/COSv2'
import { defaultEndpoint } from 'endpoints'

// Actions
const SET = 'cloud-annotations/projects/SET'

// Reducer
export default function reducer(projects = null, action = {}) {
  switch (action.type) {
    case SET:
      return action.projects
    default:
      return projects
  }
}

// Action Creators
export const setProjects = b => ({ type: SET, projects: b })

// Side Effects
export const loadProjects = async instanceId => {
  var url = `/api/projects?user=${window.user.email}`;
  console.log(url);
  const res = await fetch(url);
  let projects = await res.json();
  // projects = projects.map(bucket => ({
  //   id: bucket.name,
  //   name: bucket.name,
  //   status: bucket.status ? bucket.status : 'pending',
  //   tags: bucket.tags ? bucket.tags.join('---') : '',
  //   updatedAt: new Date(bucket.updatedAt) //.toLocaleDateString()
  // }))
  //console.log(projects)
  return setProjects(projects)
}
