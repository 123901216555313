export function initLibs() {
  let script = document.createElement('script')
  script.src = '/optin/jquery.min.js'
  script.async = true
  document.body.appendChild(script)
  setTimeout(() => {
    script = document.createElement('script')
    script.src = '/optin/bootstrap.min.js'
    script.async = true
    document.body.appendChild(script)
    // script = document.createElement("script");
    // script.src = "/optin/mdb.min.js";
    // script.async = true;
    // document.body.appendChild(script);
  }, 50)
}
