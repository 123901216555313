import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Header } from './common/Header'
import { Footer } from './common/Footer'
import { initLibs } from './common/libs'
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import {
  Team,
  PlayButton,
  PlayOverlay,
  PlayOverlayMobile,
  TeamMobile,
} from './assets'
import { useGoogleAnalytics } from 'googleAnalyticsHook'

import Vimeo from '@u-wave/react-vimeo'

export function About(props) {
  useGoogleAnalytics('lander-about')

  const [videoDisplay, setVideoDisplay] = useState(false)

  useEffect(() => {
    initLibs()
    setTimeout(() => {
      setVideoDisplay(true)
    }, 100)
  }, [])

  const [playing, setPlaying] = useState(false)

  let VidRef

  const handlePlay = () => {
    VidRef.play()
    setPlaying(true)
  }

  const handlePause = () => {
    VidRef.pause()
    setPlaying(false)
  }

  const togglePlaying = () => {
    console.log('toggling', playing)
    if (playing == true) {
      //VidRef.pause()
      setPlaying(false)
    } else {
      // VidRef.play()
      setPlaying(true)
    }
  }

  return (
    <>
      <Header />
      <div id="content">
        <section class="view" id="intro">
          <div class="site-bg-img d-flex align-items-center">
            <div class="container">
              <div class="row no-gutters">
                <div
                  class="col-md-12 col-lg-12"
                  id="video-frame"
                  style={{ marginTop: 24 }}
                >
                  {playing ? (
                    ''
                  ) : (
                    <>
                      <div class="video-overlay">
                        <PlayOverlay height={'100%'} width={'100%'} />
                      </div>
                      <div class="video-overlay-mobile">
                        <PlayOverlayMobile height={'100%'} width={'100%'} />
                      </div>
                    </>
                  )}
                  {/* <video
                    ref={(VIDREF) => {
                      if (VIDREF != null && VidRef == null) {
                        VidRef = VIDREF
                      }
                    }}
                    // poster=""
                    id="banner-video"
                    preload="auto"
                    loop
                    src="https://www.dropbox.com/s/48j0e1kt33k003p/empathic_analytics_tool.mp4?raw=1"
                    type="video/mp4"
                    onClick={togglePlaying}
                    style={{ display: videoDisplay ? '' : 'none' }}
                  /> */}
                  <div
                    id="banner-video"
                    onClick={() => {
                      togglePlaying()
                    }}
                    style={{
                      zIndex: 1,
                      position: 'relative',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Vimeo
                      ref={(VIDREF) => {
                        if (VIDREF != null && VidRef == null) {
                          VidRef = VIDREF
                        }
                      }}
                      style={{ pointerEvents: 'none' }}
                      dnt={true}
                      paused={!playing}
                      start={1}
                      video="695847840"
                      responsive={true}
                      controls={false}
                      // autoplay
                    />
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 text-left margins">
                  <div class="header" style={{ marginTop: 54 }}>
                    The Story
                  </div>
                  <div class="subtext" style={{ lineHeight: '32px' }}>
                    <p>
                      MPATH was initially conceived by transportation engineer,
                      Mike Sewell, while on a bike trip across the Netherlands.
                      As an avid bicycle commuter, he quickly realized that the
                      infrastructure, parks, and placemaking he encountered
                      across that country was in stark contrast with what he
                      typically encountered in the US. It seemed like every
                      place was built to be intuitive, enjoyable, and well
                      connected - effectively making travel there a joy. Up to
                      this point, most planners, architects, and engineers tried
                      to estimate the impact of a place or facility, but there
                      was not a quantifiable model based on a user’s actual,
                      emotional response.
                    </p>
                    <p>
                      He sought out to quantify that experience using a data
                      driven approach. After an early mathematical algorithm was
                      developed based on biometric data that could be obtained
                      from most modern wearables, a platform was created to
                      allow tests across several focused partners. These
                      partnerships helped to hone the platform and approach to a
                      point where now everyone can take advantage of an empathic
                      approach:
                    </p>
                    <p style={{ fontStyle: 'italic' }}>
                      Understanding how someone else feels mapping those
                      emotions to place, and then using what we are learning to
                      design better places.
                    </p>
                  </div>
                </div>

                <div class="col-md-12 col-lg-12 text-left margins">
                  <div class="header" style={{ marginTop: 54 }}>
                    Data Collection
                  </div>
                  <p class="">
                    We will be using some of the data we collect to help inform
                    location-based projects through studies. These studies aim
                    to understand how people interact with their surroundings
                    and identify ways to improve the environment. Your
                    participation in these studies is voluntary and you have the
                    right to opt-out at any time. By participating in these
                    studies, you have the ability to help influence the
                    development of better places for everyone. Your data will be
                    kept confidential and will only be used for research
                    purposes. We take appropriate technical and organizational
                    measures to ensure that your personal data is processed in
                    accordance with the General Data Protection Regulation
                    (GDPR) and our privacy policy.
                  </p>
                </div>

                <div
                  class="col-md-12 col-lg-12 text-left margins"
                  style={{ marginBottom: 100 }}
                >
                  <div class="header" style={{ marginTop: 80 }}>
                    The Team
                  </div>
                  <div style={{ marginTop: 32 }}>
                    <span id="team-desktop">
                      <Team />
                    </span>
                    <span id="team-mobile">
                      <TeamMobile />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}
