import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Header } from './common/Header'
import { Footer } from './common/Footer'
import { initLibs } from './common/libs'
import './style.css'
import { useGoogleAnalytics } from 'googleAnalyticsHook'

export function FAQ(props) {
  useGoogleAnalytics('lander-faq')

  useEffect(() => {
    initLibs()
  }, [])

  return (
    <>
      <Header />
      <div id="content" style={{ width: '100%', height: 'calc( 100vh )' }}>
        <iframe
          style={{ width: '100%', height: '100%', border: '0px' }}
          src="https://gresham-smith-1.gitbook.io/mpath-empathic-insights"
        />
      </div>
      <Footer />
    </>
  )
}
