import '../style.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { MobileLogo, Logo, Close, GSLogo, GSLogoSm } from '../assets'

export function Header(props) {
  const location = useLocation()

  const [drawer, setDrawer] = useState(false)

  const handleDrawer = (value) => {
    setDrawer(value)
  }

  return (
    <header>
      <nav
        class="navbar navbar-expand-lg navbar-dark scrolling-navbar" /*fixed-top*/
        id="navbar"
      >
        <div class="container">
          <Link
            to="/"
            class="nav-item"
            id="logo-header-mobile"
            style={{ width: '200px', marginLeft: 12, marginTop: 5 }}
          >
            <MobileLogo style={{ width: '150px', marginTop: '-14px' }} />
          </Link>

          <button
            class="navbar-toggler"
            id="toggler"
            type="button"
            onClick={() => {
              handleDrawer(true)
            }}
            // data-toggle="collapse"
            // data-target="#navbarContent"
            // aria-controls="navbarContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class={`collapse navbar-collapse ${drawer ? 'show' : ''}`}
            id="navbarContent"
          >
            <ul class="navbar-nav">
              <button
                id="toggler-mobile"
                class="navbar-toggler"
                type="button"
                onClick={() => {
                  handleDrawer(false)
                }}
                // data-toggle="collapse"
                // data-target="#navbarContent"
                // aria-controls="navbarContent"
                // aria-expanded="true"
                // aria-label="Toggle navigation"
                style={{ marginRight: '8px', marginTop: '8px' }}
              >
                <Close />
              </button>

              <li class="nav-item">
                <Link
                  class={`nav-link ${location.pathname == '/' ? 'active' : ''}`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    location.pathname == '/about' ? 'active' : ''
                  }`}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    location.pathname == '/app' ? 'active' : ''
                  }`}
                  to="/app"
                >
                  App
                </Link>
              </li>

              <li
                class="nav-item"
                id="logo-header"
                style={{ width: '280px', marginRight: 20, marginLeft: 10 }}
              >
                <Link to="/">
                  {/* <object
                    width="170px"
                    style={{ pointerEvents: "none" }}
                    data="/mpath_logo_tagline_white_light.svg"
                  ></object> */}
                  <Logo style={{ width: '210px', marginTop: '-10px' }} />
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    location.pathname == '/studies' ? 'active' : ''
                  }`}
                  to="/studies"
                >
                  Studies
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    location.pathname == '/faq' ? 'active' : ''
                  }`}
                  to="/faq"
                >
                  FAQ
                </Link>
              </li>
              <li class="nav-item" id="login-button">
                <Link
                  class={`nav-link ${
                    location.pathname == '/login' ? 'active' : ''
                  }`}
                  to="/login"
                  target="_blank"
                >
                  Login
                </Link>
              </li>
              <div
                id="gslogo-mobile"
                style={{ position: 'absolute', bottom: 100 }}
              >
                <GSLogoSm style={{ width: '160px', marginLeft: 20 }} />
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
